<template>
    <v-card class="pa-3 ma-3">
        <v-toolbar flat color="#1d2735" dark>
            <v-icon class="white--text" style="font-size: 45px"
                >mdi-account-supervisor-circle</v-icon
            >
            <pre><v-toolbar-title><h2>Lista Clientes</h2></v-toolbar-title></pre>
        </v-toolbar>
        <loader v-if="isloading"></loader>
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="'/'"
                    :loading="isloading"
                    :disabled="isloading"
                    color="blue"
                    class="mt-2 mx-3"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-arrow-left-bold-outline</v-icon
                    >
                </v-btn>
            </template>
            <span>Volver</span>
        </v-tooltip>
        <v-tooltip right v-if="role != 11">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    :to="{ path: `/guardar-cliente` }"
                    :loading="isloading"
                    :disabled="isloading"
                    color="orange darken-1"
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="white--text"
                        >mdi-account-plus-outline</v-icon
                    >
                </v-btn>
            </template>
            <span>Nuevo Cliente</span>
        </v-tooltip>
        <v-row>
            <v-col cols="11" md="6" justify="center">
                <FilterComponentVue
                    :headers="filter_headers"
                    v-model="filtros_prueba"
                    :onlySearch="role == 11"
                ></FilterComponentVue>
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-col cols="12" class="mb-3">
                <div class="d-flex justify-end">
                    <v-btn-toggle
                        v-model="toggle_exclusive"
                        :rounded="!isMobile ? true : false"
                        :class="{ 'btn-toggle-mobile': isMobile }"
                    >
                        <v-btn
                            ><v-icon>mdi-filter-plus-outline</v-icon
                            ><strong>
                                <pre style="color: green"> Proy. Activos</pre>
                            </strong></v-btn
                        >
                        <v-btn
                            ><v-icon>mdi-filter-minus-outline</v-icon
                            ><strong>
                                <pre style="color: red"> Proy. Inactivos</pre>
                            </strong></v-btn
                        >
                        <v-btn
                            ><v-icon>mdi-filter</v-icon
                            ><strong>
                                <pre style="color: blue"> Todos</pre>
                            </strong></v-btn
                        >
                    </v-btn-toggle>
                </div>
            </v-col>
        </v-row>

        <v-data-table
            dense
            :server-items-length="totalItems"
            :loading="loading"
            :headers="role.toString() == '6' ? headers_6 : headers"
            :items="usuarios"
            :search="search"
            :items-per-page="15"
            item-key="id"
            class="elevation-1"
            :options.sync="options"
            @update:options="onOptionsUpdate"
        >
            <template v-slot:item.fecha_alta="{ item }">
                <span v-if="item.fecha_alta">{{
                    item.fecha_alta | format_date
                }}</span>
                <span v-else>Sin información</span>
            </template>
            <template v-slot:item.action="{ item }">
                <template v-if="role.toString() != '11'">
                    <router-link
                        :to="{
                            path: `/lista-proyectos?id=${item.id}&tipo=${toggle_exclusive}`,
                        }"
                        class="action-buttons"
                    >
                        <v-icon
                            small
                            class="mr-2"
                            color="green"
                            style="font-size: 25px"
                            title="EDITAR"
                            >mdi-eye</v-icon
                        >
                    </router-link>
                    <router-link
                        v-if="role.toString() != '6'"
                        :to="{ path: `/guardar-usuario?id=${item.id}` }"
                        class="action-buttons"
                    >
                        <v-icon
                            small
                            class="mr-2"
                            color="#1d2735"
                            style="font-size: 25px"
                            title="EDITAR"
                            >mdi-pencil-outline</v-icon
                        >
                    </router-link>
                    <v-icon
                        v-if="role.toString() != '6'"
                        @click="openModal(item)"
                        small
                        class="mr-2"
                        color="red"
                        style="font-size: 25px"
                        title="BORRAR"
                        >mdi-trash-can</v-icon
                    >
                </template>
            </template>
        </v-data-table>

        <DeleteDialog
            v-model="dialog"
            :id="selectedItem?.id"
            @delete="deleteUser"
        />
    </v-card>
</template>
<script>
import debounce from "lodash/debounce";
import FilterComponentVue from "../../../components/general/FilterComponent.vue";
import { servicios_mixin } from "../../../global_mixins/servicios_mixin";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";

export default {
    mixins: [servicios_mixin],

    components: {
        FilterComponentVue,
        DeleteDialog,
    },
    data() {
        return {
            filtros_prueba: {
                search: "",
                estado: {},
                semana: {},
                servicio: {},
            },
            filter_headers: [
                {
                    title: "Tipo de Proyecto",
                    type: "select",
                    active: false,
                    model: "semana",
                    item_text: "nombre",
                    item_value: "id",
                    items: [
                        {
                            id: 0,
                            nombre: "Unico",
                        },
                        {
                            id: 1,
                            nombre: "Semanal",
                        },
                        {
                            id: 3,
                            nombre: "Mensual",
                        },
                    ],
                },
                {
                    title: "Producto Contratado",
                    type: "select",
                    active: false,
                    model: "servicio",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
                {
                    title: "Estado Proyecto",
                    type: "select",
                    active: false,
                    model: "estado",
                    item_text: "nombre",
                    item_value: "id",
                    items: [],
                },
            ],
            search: "",
            loading: false,
            headers: [
                { text: "Nombre", value: "nombre", sortable: false },
                {
                    text: "Nombre Fiscal",
                    value: "nombre_fiscal",
                    sortable: false,
                },

                /*{
                    text: "Nombre Comercial",
                    value: "nombre_comercial",
                    sortable: false,
                },*/
                { text: "Email", value: "email", sortable: false },
                { text: "Teléfono", value: "telefono", sortable: false },
                { text: "Fecha Alta", value: "fecha_alta", sortable: true },
                { text: "Acciones", value: "action", sortable: false },
            ],
            headers_6: [
                { text: "Nombre", value: "nombre", sortable: false },
                {
                    text: "Nombre Comercial",
                    value: "nombre_comercial",
                    sortable: false,
                },
                { text: "Email", value: "email", sortable: false },
                { text: "Fecha Alta", value: "fecha_alta", sortable: false },
                { text: "Acciones", value: "action", sortable: false },
            ],
            usuarios: [],
            selectedItem: 0,
            totalItems: 67,
            options: {
                page: 1,
                itemsPerPage: 15,
                sortBy: [],
                sortDesc: [],
            },
            dialog: false,
            toggle_exclusive: 0,

            // Auxiliares
            sortBy: "",
            sortDesc: true,
        };
    },
    created() {
        this.getEstados();

        this.getActiveClientes();
    },
    mounted() {
        this.filter_headers[1].items = this.servicios;
    },
    watch: {
        servicios(val) {
            this.filter_headers[1].items = val;
        },
        search: debounce(function (n) {
            this.getActiveClientes();
        }, 200),
        filtros_prueba: {
            deep: true,
            handler: debounce(function (n) {
                this.getActiveClientes();
            }, 200),
        },
        toggle_exclusive(n) {
            /*if (this.toggle_exclusive == 0) {
                this.getActiveClientes();
            } else if (this.toggle_exclusive == 1) {
                this.getInactiveClientes();
            } else if (this.toggle_exclusive == 2) {
                this.getAllClientes();
            } else {
                this.usuarios = [];
            }*/
            this.getActiveClientes();
        },
    },
    methods: {
        // Listar clientes
        getActiveClientes() {
            this.loading = true;
            axios
                .get(`api/get-all-clientes-proyectos`, {
                    params: {
                        amount: this.options.itemsPerPage,
                        page: this.options.page,
                        sortBy: this.options.sortBy[0],
                        sortDesc: this.options.sortDesc[0],
                        activo:
                            this.toggle_exclusive == 0
                                ? 1
                                : this.toggle_exclusive == 1
                                ? 0
                                : null,
                        busqueda: this.filtersParams.search,
                        estado: this.filtersParams.estado,
                        servicio: this.filtersParams.servicio,
                        semana: this.filtersParams.semana,
                    },
                })
                .then(
                    (res) => {
                        this.usuarios = res.data.users.data;
                        this.totalItems = res.data.users.total;
                        this.loading = false;

                        for (let i = 0; i < this.usuarios.length; i++) {
                            const element = this.usuarios[i];
                            element.created_at = new Date(
                                element.created_at
                            ).toLocaleDateString();
                        }
                    },
                    (err) => {
                        this.$toast.error("Error consultando Usuario");
                    }
                );
        },
        onOptionsUpdate(newOptions) {
            this.options = newOptions;
            if (this.options.sortBy.length > 0) {
                this.sortBy = this.options.sortBy[0];
            } else {
                this.options.sortBy = [this.sortBy];
            }
            if (this.options.sortDesc.length > 0) {
                this.sortDesc = this.options.sortDesc[0];
            } else {
                this.options.sortDesc = [!this.sortDesc];
            }
            this.getActiveClientes();
        },

        // Eliminar cliente
        openModal(item) {
            this.selectedItem = this.usuarios.indexOf(item);
            this.dialog = true;
        },
        deleteUser() {
            axios
                .get(
                    `api/delete-usuario/${this.usuarios[this.selectedItem].id}`
                )
                .then(
                    (res) => {
                        this.$toast.sucs("Usuario eliminado");
                        this.getActiveClientes();
                        this.dialog = false;
                    },
                    (err) => {
                        this.$toast.error("Error eliminando Usuario");
                    }
                );
            this.getUsuarios();
        },

        // Metodo general
        getEstados() {
            axios.get("api/get-proyecto-estados").then((res) => {
                this.filter_headers[2].items = res.data;
            });
        },

        // En desuso
        /*getAllClientes() {
            axios.get(`api/get-all-clientes`).then(
                (res) => {
                    this.usuarios = res.data.users;
                    for (let i = 0; i < this.usuarios.length; i++) {
                        const element = this.usuarios[i];
                        element.created_at = new Date(
                            element.created_at
                        ).toLocaleDateString();
                    }
                },
                (err) => {
                    this.$toast.error("Error consultando Usuario");
                }
            );
        },
        getInactiveClientes() {
            axios.get(`api/get-all-clientes-inactive-proyectos`).then(
                (res) => {
                    this.usuarios = res.data.users;
                    for (let i = 0; i < this.usuarios.length; i++) {
                        const element = this.usuarios[i];
                        element.created_at = new Date(
                            element.created_at
                        ).toLocaleDateString();
                    }
                },
                (err) => {
                    this.$toast.error("Error consultando Usuario");
                }
            );
        },
        verProyectos() {
            axios
                .get(
                    `api/delete-usuario/${this.usuarios[this.selectedItem].id}`
                )
                .then(
                    (res) => {
                        this.$toast.sucs("Usuario eliminado");
                        this.dialog = false;
                    },
                    (err) => {
                        this.$toast.error("Error eliminando Usuario");
                    }
                );
            this.getUsuarios();
        },*/
    },
    computed: {
        role: function () {
            return localStorage.getItem("role");
        },
        isloading: function () {
            return this.$store.getters.getloading;
        },
        filtersParams() {
            let params = {};

            if (this.filtros_prueba.search) {
                params.search = this.filtros_prueba.search;
            }

            this.filter_headers.forEach((head) => {
                if (this.filtros_prueba[head.model]) {
                    if (head.type == "date") {
                        if (this.filtros_prueba[head.model].start) {
                            params[`${head.model}_inicio`] =
                                this.filtros_prueba[head.model].start;
                        }
                        if (this.filtros_prueba[head.model].end) {
                            params[`${head.model}_fin`] =
                                this.filtros_prueba[head.model].end;
                        }
                    } else if (
                        this.filtros_prueba[head.model].value ||
                        this.filtros_prueba[head.model].nombre
                    ) {
                        params[head.model] =
                            this.filtros_prueba[head.model].value ||
                            this.filtros_prueba[head.model].nombre;
                    }
                }
            });

            return params;
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown; // Detecta pantallas más pequeñas
        },
    },
};
</script>

<style scoped>
.btn-toggle-mobile {
    flex-direction: column;
}
</style>
